@mixin dottedBackground($width, $height)
    background-image: radial-gradient(#E0E3FD88 3px, transparent 3px)
    background-position: 0 0
    background-size: 13px 13px
    position: absolute
    height: $height
    width: $width

.dottedCardWrapper
    position: relative
    width: max-content
    z-index: 0

    &::before
        @include dottedBackground(154px, 232px)
        content: ' '
        top: -25px
        left: -25px
        z-index: -1

    &::after
        @include dottedBackground(154px, 232px)
        content: ' '
        bottom: -25px
        right: -25px
        z-index: -1
